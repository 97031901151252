.footer {
    color: $white;
    position: relative;
    margin-bottom: 35px;
    width: 100%;
    text-align: center;
    padding-top: 20px;
    height: 50px;
    margin-top: -50px;


    &__copyright {
        font-weight: bold;
    }

    // position: fixed;
    bottom: 0;
    left: 0;

    // @media (min-height: $br-991) {
    //     position: fixed;
    //     bottom: 0;
    //     left: 0;
    // }

    // @media (max-width: $br-860) and (min-height: $br-860) {
    //     position: fixed;
    //     bottom: 0;
    //     left: 0;
    // }

    p {
        font-size: $small;
        line-height: 1.55;
        margin: 0 $margin-header;

        @media (max-width: $br-860) {
            margin: 0 $margin-header - 20;
        }

        @media (max-width: $br-500) {
            margin: 0 $margin-header - 40;
            font-size: $small - 1;
        }

        @media (max-width: $br-350) {
            font-size: $small - 3;
        }
    }
}
