.header {
    position: relative;
    // top: 0;
    padding-left: $margin-header - 10;

    .padding{
        width: 1px;
        float:left;
        max-height:$margin-header - 10;
        height: 5vh;
    }

    &__logo{
        float:left;
        clear:left;
    }

    img{
        width: 20vw;
        max-width:258px;
    }

    @media (max-width: $br-860) {
        padding-left: $margin-header - 30;

        img{
            width: 30vw;
        }
    }

    @media (max-width: $br-500) {
        padding-left: $margin-header - 40;

        img{
            width: 40vw;
        }
    }


}
