$white:           #FFFFFF;
$black:           #000000;
$gray:            #ADADAD;
$blue:            #3B4EC2;
$dark-blue:       #1B1839;
$green:           #228B22;
$red:             #EEA7FF;
$yellow:          #F9E29F;
$purple:          #B3BEFF;

$margin-header:     57px;
$margin-185:        185px;
$margin-220:        220px;

$br-1200:           1200px;
$br-1024:           1024px;
$br-991:            991px;
$br-860:            860px;
$br-767:            767px;
$br-710:            710px;
$br-620:            620px;
$br-600:            600px;
$br-500:            500px;
$br-400:            400px;
$br-350:            350px;

$big:               50px;
$medium:            20px;
$small:             11px;
