.background{
    background: $dark-blue;
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;left: 0;

    svg{
       min-width: 100%;
       min-height: 100%;
    }
}

body {
   overflow: hidden;
}