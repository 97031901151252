.content {
    color: $white;
    width: 100%;
    align-items: center;
    min-height: 80vh;
    display: flex;

    &__container {
        max-width: 715px;
        margin: auto;
        text-align: center;

        @media (max-width: $br-767) {
            text-align: center;
        }

        @media (max-width: $br-860) {
            margin-left: $margin-header - 20;
            margin-right: $margin-header - 20;
        }

        @media (max-width: $br-500) {
            margin-left: $margin-header - 40;
            margin-right: $margin-header - 40;
        }
    }

    &__header {
        width: 100%;

        &--h2 {
            font-weight: bold;
            font-size: $big;
            line-height: 1.3;
            margin: 0;

            @media (max-width: $br-1200) {
                font-size: $big - 5;
                line-height: 1.2;
            }

            @media (max-width: $br-767) {
                font-size: $big - 6;
            }

            @media (max-width: $br-600) {
                font-size: $big - 15;
            }

            @media (max-width: $br-500) {
                font-size: $big - 20;
            }

            @media (max-width: $br-400) {
                font-size: $big - 22;
            }
        }
    }

    &__info {
        font-size: $medium;
        line-height: 1.45;

        @media (max-width: $br-1200) {
            font-size: $medium - 2;
        }

        @media (max-width: $br-600) {
            font-size: $medium - 4;
        }

        @media (max-width: $br-500) {
            font-size: $medium - 5;
        }

        @media (max-width: $br-400) {
            font-size: $medium - 6;
        }
    }
}
