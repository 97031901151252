.newsletter-signup-form-container{
    margin-top: $margin-header;
    position: relative;
    background: $white;
    border-radius: 2px;
    border: none;

    @media (max-width: $br-860) {
        margin-top: $margin-header - 10;
    }

    &__thank-you {
        height: 100%;
        width: 100%;
        color: $purple;

        p {
            margin: 0;
            @media (max-width: $br-767) {

                text-align: center;
                width: 100%;
            }


        }
    }
    &__error{
        color: $yellow;
    }


    &__newsletter-signup-form {
        display: flex;
        // padding-left: 21px;
        padding-right: 16px;
        padding-top: 16px;
        padding-bottom: 16px;

        &--mobile {
            padding-left: 0 !important;
            padding-right: 0 !important;
            padding-top: 18px !important;
            padding-bottom: 0 !important;

            display: none;
            @media (max-width: $br-767) {
                display: block;

            }
        }

        @media (max-width: $br-767) {
            // padding-left: 16px;
            padding-top: 11px;
            padding-bottom: 11px;
        }

        .input-wrapper {
            border-radius: 2px;
            border: none;
            width: 70%;
            position: relative;
            display: flex;
            position: relative;
            align-items: center;

            i {
                position: absolute;
                width: 1px;
                height: 100%;
                background-color: $gray;
                left: 0;
                animation-name: blink;
                animation-duration: 800ms;
                animation-iteration-count: infinite;
                opacity: 1;
            }

            input {
                height: 100%;
                width: 90%;
                border: none;
                outline: none;
                font-size: 18px;
                line-height: 145%;
                color: transparent;
                text-shadow: 0 0 0 $black;
                padding-left: 13px;

                &::placeholder {
                    text-shadow: none;
                    color: $gray;
                    opacity: 0.8;
                }

                @media (max-width: $br-1024) {
                    width: 100%;
                    height: 30px;
                    font-size: 15px;
                }
            }

            @keyframes blink {
                from { opacity: 1; }
                to { opacity: 0; }
            }

            @media (max-width: $br-767) {
                width: 100%;
            }
        }

        .input-wrapper input:focus+i {
            display: none !important;
        }

        button {
            width: 30%;
            font-size: 18px;
            line-height: 20px;
            padding: 12px 23px;
            color: $white;
            font-weight: bold;
            border-radius: 3px;
            background-color: $blue;
            outline: none;
            border: none;

            &:hover {
                cursor: pointer;
                background-color: darken($blue, 5%);
            }

            @media (max-width: $br-767) {
                display: none;
            }
        }

        &--mobile-btn {
            display: none;

            @media (max-width: $br-767) {
                display: block !important;
                width: 100% !important;
                padding-top: 14px !important;
                padding-bottom: 15px !important;
                font-size: 18px !important;
            }
        }
    }

    &__message-container {
        text-align: center;
        @media (max-width: $br-767) {
            text-align: left;
        }
        .errors{
            margin-top: 20px;
        }

        span{
            @media (max-width: $br-1024) {
                font-size: 12px;
            }
        }

    }
}
